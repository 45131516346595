const YoutubeVideo = ({ycode, autoplay = 1, loop = 1, mute = 1}) => {
  return (
    <>
      {ycode &&
        <div className="yVideoBlock">
            <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${ycode}?autoplay=${autoplay}&mute=${mute}&controls=0&version=3&loop=${loop}&playlist=${ycode}`} title="YouTube" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        </div>
      }
    </>
  )
}

export default YoutubeVideo