import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import bannerBg from "../../assets/img/Blog/bannerBg.png";
// import blog from '../../assets/img/Blog/blog.png';
import { NavLink } from "react-router-dom";
import constants from "../../helpers/constants";

function Blog(props) {
  const [blogData, setBlogData] = useState([]);
  const [blogBanner, setBlogBanner] = useState(null);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState();
  const [resultCount, setResultCount] = useState();
  const [hideLoadMoreLoader, setHideLoadMoreLoader] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  const blog_url = `${constants.urls.API}/getAllBlog?limit=9&page=${page}`;

  function blogInfo() {
    setIsLoaded(false)
    Promise.all([
      fetch(blog_url, {
        method: `GET`,
      }),
    ])
      .then((res) => {
        return Promise.all(
          res.map((response) => {
            return response.json();
          })
        );
      })
      .then((result) => {
        if (result && result[0]) {
          const blogs = result[0].blog.data;
          setResultCount(result[0].allItems);
          setHideLoadMoreLoader(true);
          setBlogBanner(result[0].blogMainBanner)
          if (blogData.length) {
            let oldData = blogData.concat(blogs);
            setBlogData(oldData);
          } else {
            setBlogData(blogs);
          }
        }
        setIsLoaded(true)
      })
      .catch((err) => {
        console.log(err);

        /* scroll animation end */
      });
  }
  function loadMoreItems() {
    setPage(page + 1);
    setHideLoadMoreLoader(false);
  }

  useEffect(() => {
    blogInfo();
  }, [props.match.params.id, page]);

  useEffect(() => {
    if (resultCount == blogData.length) {
      setLoadMore(true);
    } else {
      setLoadMore(false);
    }
  }, [blogData]);

  if (!isLoaded) {
    return (
      <div className="loader">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  
  return (
    <div className="blog">
      {blogBanner &&
        <div
          className="blog__banner"
          style={{ backgroundImage: `url(${constants.urls.UPLOAD+blogBanner.path})` }}
        >
          {blogBanner?.title && <h1 className="blog__banner__title">{blogBanner.title}</h1>}
        </div>
      }
      <div className="blog__list">
        <div className="container container--middle--lg">
          <div className="row blog__list__row">
            {blogData &&
              blogData.map((item, i) => (
                <div className="col-md-6 col-lg-4 blog__list__row__col" key={i}>
                  <div className="blog__list__row__col__info">
                    <NavLink to={`/blog/${item.id}`}>
                      <div className="blog__list__row__col__info__image">
                        <Image
                          src={
                            item.image !== ""
                              ? constants.urls.UPLOAD+''+item.image
                              : "https://i.stack.imgur.com/y9DpT.jpg"
                          }
                          alt={item.title}
                        />
                      </div>
                      <div className="blog__list__row__col__info__content">
                        <p className="blog__list__row__col__info__content__date">
                          {new Date(item.created_at).toISOString().slice(0, 10)}
                        </p>
                        <div className="blog__list__row__col__info__content__text">
                          <h3 className="blog__list__row__col__info__content__text__title">
                            {item.title}
                          </h3>
                          <p className="blog__list__row__col__info__content__text__description">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div
        className={`blog-load ${loadMore ? "hide" : ""}`}
        onClick={() => loadMoreItems()}
      >
        <span className="text">Load more</span>
        <div
          className={`spinner-border ${hideLoadMoreLoader ? "hide" : ""}`}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
}

export default Blog;
