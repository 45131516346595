import React, {useEffect, useState} from 'react';
import { Image } from 'react-bootstrap';
import bannerBg from '../../assets/img/Blog/bannerBg.png';
import blog from '../../assets/img/Blog/blog.png';
import { NavLink } from 'react-router-dom';
import constants from "../../helpers/constants";



function BlogSingle (props) {
    const [ blogData, setBlogData ] = useState([]);
    const [ blogSingle, setBlogSingle ] = useState([]);
    const blog_url = `${constants.urls.API}/getAllBlog?limit=3&page=1`;
    const id = props.match.params.id;
    const single_url = `${constants.urls.API}/getBlog/${id}`;
    const [isLoaded, setIsLoaded] = useState(false);

    function previousBlogInfo() {
        Promise.all([
            fetch(blog_url, {
                method: `GET`
            })

        ])
        .then( res =>{return Promise.all(res.map( response => {  return response.json() }))}) .then( result =>{
            if(result && result[0]) {
                const blogs = result[0].blog;
                setBlogData(blogs)
            }
        })
        .catch( err => {
            console.log(err);

            /* scroll animation end */
        })
    }
    function singleBlog() {
        setIsLoaded(false)
        Promise.all([
            fetch(single_url, {
                method: `GET`
            })

        ])
        .then( res =>{return Promise.all(res.map( response => {  return response.json() }))}) .then( result =>{
            // setBlogSingle(result[0].blog)
            if(result && result[0]) {
                setBlogSingle(result[0])
            }
            setIsLoaded(true)
        })
        .catch( err => {
            console.log(err);

            /* scroll animation end */
        })
    }
    useEffect(()=>{
        previousBlogInfo()
        singleBlog()
    },[props.match.params.id]);

    if (!isLoaded) {
        return (
          <div className="loader">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        );
      }
    
    return (
        <div className="blogSingle">
            <div className="blogSingle__banner" style={{ backgroundImage: `url(${constants.urls.UPLOAD+blogSingle.blog.banner_image})` }}>
            {blogSingle.blog?.title && <h1 className="blogSingle__banner__title">
                    {blogSingle.blog.title}
                </h1>
            }
            </div>
            <div className="blogSingle__content pt-5" style={{backgroundColor: blogSingle.blog?.bg_color}}>
                <div className="container container--middle--lg">
                        {
                            blogSingle.blogSchemes.blogScheme && blogSingle.blogSchemes.blogScheme.length > 0 &&
                            blogSingle.blogSchemes.blogScheme.map((item,i)=>(
                                <div className="row" key={i}>
                                    {
                                        item.map((column,j)=>(
                                            <div className={column.class} key={j}>
                                                {
                                                    column.image &&
                                                    <Image  src={column.image} className="blogSingle__colImage"   alt="blog image"/>
                                                }  
                                                {
                                                    column.youtube &&
                                                    <div className="blogSingle__video">
                                                        <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${column.youtube.slice(32, 43)}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                    </div>
                                                }                                              
                                                <h2 style={{color: column.title_color}}>{column.header}</h2>
                                                <p style={{color: column.description_color}}>{column.description}</p>
                                            </div>
                                        ))
                                    }                                    
                                </div>
                            ))
                        }
                </div>
            </div>
            <div  className="blogSingle__suggestions">
                <div className="container container--middle--lg">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="blogSingle__suggestions__title">Suggestions of Previous Blogs</h2>
                        </div>
                    </div>


                    <div className="row blogSingle__suggestions__list__row">
                        {
                            blogData.data && blogData.data.map((item, i)=>{
                                if(item.id == id){
                                    return null
                                }
                                return (
                                <div className="col-md-6 col-lg-4 blogSingle__suggestions__list__row__col" key={i}>
                                    <div className="blogSingle__suggestions__list__row__col__info">
                                        <NavLink to={`/blog/${item.id}`}>
                                            <div className="blogSingle__suggestions__list__row__col__info__image">                                        
                                                    <Image  src={constants.urls.UPLOAD+''+item.image}   alt="blog image"/>                                        
                                            </div>
                                            <div className="blogSingle__suggestions__list__row__col__info__content">
                                                <p className="blogSingle__suggestions__list__row__col__info__content__date">
                                                    {new Date(item.created_at).toISOString().slice(0, 10)}
                                                </p>
                                                <div className="blogSingle__suggestions__list__row__col__info__content__text">                                        
                                                        <h3 className="blogSingle__suggestions__list__row__col__info__content__text__title">{item.title}</h3>                                        
                                                    <p className="blogSingle__suggestions__list__row__col__info__content__text__description">{item.description}</p>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            )})
                        }                      

                    </div>


                </div>
            </div>
        </div>
    )
}

export default BlogSingle ;
