import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import bannerBg from "../../assets/img/Blog/bannerBg.png";
import constants from "../../helpers/constants";

function WorkSingle(props) {
  const [workSingle, setWorkSingle] = useState([]);
  const id = props.match.params.id;
  const single_url = `${constants.urls.API}/getWork/${id}`;
  const [isLoaded, setIsLoaded] = useState(false);


  function singleWork() {
    setIsLoaded(false)
    Promise.all([
      fetch(single_url, {
        method: `GET`,
      }),
    ])
      .then((res) => {
        return Promise.all(
          res.map((response) => {
            return response.json();
          })
        );
      })
      .then((result) => {
        if (result && result[0]) {
          setWorkSingle(result[0]);
        }
        setIsLoaded(true)
      })
      .catch((err) => {
        console.log(err);

        /* scroll animation end */
      });
  }
  useEffect(() => {
    singleWork();
  }, [props.match.params.id]);
  if (!isLoaded) {
    return (
      <div className="loader">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="workSingle">
      <div
        className="workSingle__banner"
        style={{ backgroundImage: `url(${constants.urls.UPLOAD+workSingle.work.banner_image})` }}
      >
        <h1 className="workSingle__banner__title">{workSingle.work.title}</h1>
      </div>
      <div className="workSingle__content pt-5 py-5" style={{backgroundColor: workSingle.work?.bg_color}}>
        <div className="container container--middle--lg">
          {workSingle &&
            workSingle.workSchemes.length > 0 &&
            workSingle.workSchemes.map((item, i) => (
              <div className="row" key={i}>
                {item.map((column, j) => (
                  <div className={column.class} key={j}>
                    {
                      column.image &&
                      <Image
                        src={column.image}
                        className={(column.col == 12) ? 'workSingle__colImage mb-0' : 'workSingle__colImage'}
                        alt="work image"
                      />
                    }

                    {
                      column.youtube &&
                      <div className={(column.col == 12) ? 'workSingle__video mb-0' : 'workSingle__video'}>
                        <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${column.youtube.slice(32, 43)}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </div>
                    }
                    
                    {column.header && <h2 style={{color: column.title_color}}>{column.header}</h2> }
                    {column.description && <p className="services__description__text" style={{color: column.description_color}}>{column.description}</p>}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default WorkSingle;
