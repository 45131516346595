import { Image } from 'react-bootstrap';
import logo from '../../assets/img/Logo.svg';
import fb from '../../assets/img/Footer/social/Facebook.svg';
import linkdin from '../../assets/img/Footer/social/linkdin.svg';
import Behance from '../../assets/img/Footer/social/Behance.svg';
import constants from "../../helpers/constants";

function CommingSoon (props) {
  const contact_email = `${constants.urls.API}/AddCommingSoon`;
  const contactSubmit= ( e ) => {
    e.preventDefault();
    const formData = new FormData();
    const email = document.querySelector('#contact_email').value;
    const message = document.querySelector('#contact_message').value;
    //validation
    var emailAlertText = document.querySelector('#email_alert_contact');
    var messageAlertText = document.querySelector('#message_alert_contact');
    var sentForm = document.querySelector('#sent_form_contact');

    if(email != ''){
        if( /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)){
            emailAlertText.innerHTML = "";
            //return true;
        } else {
            emailAlertText.innerHTML = "You have entered an invalid email address!";
            //return false;
        }

    }else{
        emailAlertText.innerHTML = "This field is required";
        //  return false;
    }

    // if(message != ''){
    //     messageAlertText.innerHTML = "";
    // } else {
    //     messageAlertText.innerHTML = "This field is required";
    //     return false;
    // }
    formData.append('email', email);
    formData.append('message', message);

    fetch(contact_email, {
        method: 'POST',
        body: formData,
    })
        .then(response => response.json())
        .then(result => {
            console.log('Success:', result);
            sentForm.innerHTML = "Your message has been successfully sent.";
            setTimeout(function(){
                sentForm.innerHTML = "";
            }, 3000);
            document.getElementById("commingSoonForm").reset();
            emailAlertText.innerHTML = "";
            messageAlertText.innerHTML = "";
        })
        .catch(error => {
            console.error('Error:', error);
        });
  }
  return (
    <div>
      <header className= {`mobile--menu header-type-`}  >
          <div className="container container--max">
              <div className="row">
                  <div className="col-md-12 col-xs-12">
                      <div  className="mobile--menu__main">
                          <Image src={logo} className="mobile--menu__main__img"  alt="logo"/>
                      </div>
                  </div>
              </div>
          </div>
      </header>
      <main>
        <h1 style={{textAlign: 'center',color: '#08A4AA',fontSize: 70, marginBottom: 64}}>Coming Soon..</h1>
        <form method="POST" action={contact_email} id="commingSoonForm" >
            <div style={{maxWidth: 708, marginLeft: 'auto', marginRight: 'auto', marginBottom: 64}}>
              <h4 id="email_alert_contact" className="error_message"></h4>
              <input id="contact_email" required type="text" name="email"  placeholder="email@email.com*" className="home__contact__form__main__email"/>
              <input id="contact" type="hidden" name="contact"  value="Contact Us"/>

              <div  className="home__contact__form__main__row">
                  <div className="home__contact__form__main__row__textarea">
                      <label className="home__contact__form__main__row__textarea__label" htmlFor="message">Message</label>
                      <textarea name="message" required id="contact_message" cols="30" rows="8" placeholder="Write your message here"  className="home__contact__form__main__row__textarea__main"></textarea>
                  </div>
              </div>
              <button onClick={contactSubmit} className="home__contact__form__main__submit" >Send</button>
              <h4 id="sent_form_contact" className="success_message"></h4>
            </div>
        </form>
      </main>
      <footer className="footer">
          <ul className="footer__list">
              <li className="footer__list__item">
                  <a  className="footer__list__item__link" rel="noreferrer"  href="https://www.linkedin.com/company/circle-technical-design" target="_blank" >
                      <Image src={linkdin}  className="footer__list__item__link__image"  alt="social icon"/>
                                          
                  </a>
              </li>
              <li  className="footer__list__item">
                  <a className="footer__list__item__link" rel="noreferrer"  href="https://www.facebook.com/circletechnicaldesign" target="_blank" >
                      <Image src={fb}  className="footer__list__item__link__image"  alt="social icon"/>
                  </a>
              </li>
              <li className="footer__list__item">
                  <a className="footer__list__item__link" rel="noreferrer"  href="https://www.behance.net/circletechnical/" target="_blank">
                      <Image src={Behance}  className="footer__list__item__link__image"  alt="social icon"/>
                  </a>
              </li>
          </ul>
          <p className="footer__copyright"> <span>Circle technical design</span> all rights reserved ©</p>
      </footer>
    </div>
  )
}

export default CommingSoon;