import React, { useEffect, useState } from "react";
import bannerBg from "../../assets/img/Blog/bannerBg.png";
import { NavLink } from "react-router-dom";
import constants from "../../helpers/constants";

function Career(props) {
  const [pageData, setPageData] = useState(null);
  const [vacancyListData, setVacancyListData] = useState([]);
  const [blogBanner, setBlogBanner] = useState(null);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState();
  const [resultCount, setResultCount] = useState();
  const [hideLoadMoreLoader, setHideLoadMoreLoader] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  const page_url = `${constants.urls.API}/getCareer?limit=9&page=${page}`;

  function getPageData() {
    setIsLoaded(false)
    Promise.all([
      fetch(page_url, {
        method: `GET`,
      }),
    ])
      .then((res) => {
        return Promise.all(
          res.map((response) => {
            return response.json();
          })
        );
      })
      .then((result) => {
        if (result && result[0]) {
          const page = result[0].career;
          const vacancies = result[0].vacancies;
          setPageData(page)
          setResultCount(result[0].allItems);
          setHideLoadMoreLoader(true);
          if (vacancyListData?.data?.length) {
            let oldData = {
              ...result[0].vacancies,
              data: [
                  ...vacancyListData.data,
                  ...result[0].vacancies.data,
              ]
            };
            setVacancyListData(oldData);
          } else {
            setVacancyListData(vacancies);
          }
        }
        setIsLoaded(true)
      })
      .catch((err) => {
        console.log(err);

        /* scroll animation end */
      });
  }
  function loadMoreItems() {
    setPage(page + 1);
    setHideLoadMoreLoader(false);
  }

  useEffect(() => {
    getPageData();
  }, [props.match.params.id, page]);

  useEffect(() => {
    if (resultCount == vacancyListData?.data?.length) {
      setLoadMore(true);
    } else {
      setLoadMore(false);
    }
  }, [vacancyListData]);
  
  if (!isLoaded) {
    return (
      <div className="loader">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="page">
      {pageData &&
        <div
          className="banner"
          style={{ backgroundImage: `url(${constants.urls.UPLOAD+pageData.logo})` }}
        >
          {pageData?.title && <h1>{pageData.title}</h1>}
        </div>
      }
      <div className="container container--middle--lg">
        {pageData && 
          <div className="page__content">
            <h2 className="page__subtitle">{pageData.sub_title}</h2>
            <div className="page__text">{pageData.description}</div>
          </div>
        }
        <div className="row">
          {vacancyListData?.data &&
            vacancyListData.data.map((item, i) => (
              <div className="col-md-4 col-sm-6 mb-4">
                <NavLink to={`/vacancy/${item.id}`} key={i} className="vacancy__item">{item.title}</NavLink>
              </div>
            ))}
        </div>
      </div>
      {(vacancyListData?.last_page > 1 && vacancyListData?.current_page < vacancyListData?.last_page) &&
        <div
          className={`blog-load ${loadMore ? "hide" : ""}`}
          onClick={() => loadMoreItems()}
        >
          <span className="text">Load more</span>
          <div
            className={`spinner-border ${hideLoadMoreLoader ? "hide" : ""}`}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      }
    </div>
  );
}

export default Career;
