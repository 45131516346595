import {React, useEffect, useState} from 'react';
import { Image, Tabs, Tab } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import bannerBg from '../../assets/img/Shop/bannerBg.png';
import product from '../../assets/img/Shop/shop-product-image.png';
import constants from "../../helpers/constants";



function Shop (props) {
    const shop_main_url = `${constants.urls.API}/getShopImage`;
    const cat_url = `${constants.urls.API}/getCategory`;
    const all_shop_url = `${constants.urls.API}/getAllShop`;
    const [ data, setData ] = useState(null);
    const [ request_data, setReqData ] = useState(null);
    const [ request_category_data, setReqCategoryData ] = useState(null);
    const [ cat_data, setCatData ] = useState(null);
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [hideLoadMoreLoader, setHideLoadMoreLoader] = useState();
    const [allPage, setAllPage] = useState(1);
    const [allResultCount, setAllResultCount] = useState();
    const [loadMore, setLoadMore] = useState();

    const fetchAllData = async (category, page) => {
        const url = (category && category != 'firstTab') ? `${all_shop_url}?category_id=${category}&page=${page ? page : 1}` : `${all_shop_url}?page=${page ? page : 1}`
        const data = await fetch(`${url}`, { method: "GET" })
        const shop = await data.json()
        return shop.shop
    }
    function getData(page) {
        fetchAllData(null, page).then(data => {
            setHideLoadMoreLoader(true);
              if (request_data && request_data?.data?.length) {
                let oldData = {
                    ...data,
                    data: [
                        ...request_data.data,
                        ...data.data,
                    ]
                  };
                setReqData(oldData);
              } else {
                setReqData(data);
              }
        })
    }
    
    function getDataByCategory(category, page) {
        fetchAllData(category, page).then(data => {
            setHideLoadMoreLoader(true);
            if (data) {
                const shop = data;
                if (request_category_data && request_category_data?.data?.length) {
                  let oldData = {
                      ...shop,
                      data: [
                          ...request_category_data.data,
                          ...shop.data,
                      ]
                    };
                  setReqCategoryData(oldData);
                } else {
                  setReqCategoryData(shop);
                }
              }
        })
    }

    useEffect(()=>{
        Promise.all([
            fetch(shop_main_url, {
                method: `GET`
            }),
            fetch(cat_url, {
                method: `GET`
            }),
            fetch(`${all_shop_url}`, {
                method: `GET`
            }),

        ]).then( res =>{
            return Promise.all(
                res.map( response => {
                    return response.json()
                })
            )
        }) .then( result =>{
            result.forEach( ( el,i ) => {
                if( el.success ) {
                    setHideLoadMoreLoader(true);
                    setIsLoaded(true);
                    switch (i) {
                        case 0:
                            setData(el.shop_image)
                            break;
                        case 1:
                            setCatData(el.category)
                            break;
                        case 2:
                            setReqData(el.shop)
                            break;
                    }
                }
            })
        })
            .catch( err => {
                console.log(err);

                /* scroll animation end */
            })
    },[props.match.params.id]);

    // console.log('test--',cat_data, request_data);
    if (!isLoaded) {
        return <div className="loader">
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    }
    const TabKey = (index) =>{
        if(index == 0){
            return "firstTab";
        }else{
            return "Tab-"+index;
        }
    }
    const imgOrderClass = (index) =>{
        if(index%2 != 0){
            return "order-1 order-md-12";
        }else{
            return "";
        }
    }
    const infoOrderClass = (index) =>{
        if(index%2 != 0){
            return "order-12 order-md-1";
        }else{
            return "";
        }
    }

    function loadMoreAllItems(page) {
        getData(page);
        setHideLoadMoreLoader(false);
    }

    function loadMoreItemsByCategory(category, page) {
        getDataByCategory(category, page);
        setHideLoadMoreLoader(false);
    }

    async function loadTabData(category) {
        setHideLoadMoreLoader(true);
        if(category == 'firstTab'){
            const data = await fetchAllData()
            setReqData(data)
        }else{
            setReqCategoryData(null);
            const data = await fetchAllData(category)
            setReqCategoryData(data);
        }
        setHideLoadMoreLoader(false);
    }

    return (
        <div className="shop">
            <div className="shop__banner" style={{ backgroundImage: `url(${data && constants.urls.UPLOAD + data[0].path})` }}>
                {(data && data[0]?.title) && <h1 className="shop__banner__title">
                    {data[0]?.title}
                </h1>
                }
            </div>
            <div className="shop__content">
                <Tabs onSelect={(k) => loadTabData(k)} defaultActiveKey="firstTab" id="uncontrolled-tab-example" className="shop__content__tabs">
                    <Tab eventKey="firstTab" title="All" className="shop__content__tabs__tab">
                        <div className="shop__content__tabs__tab__shop">
                            {request_data?.data?.length && request_data.data.map(function(it, k) {
                                return <div className="shop__content__tabs__tab__shop__content">
                                    <div className={`shop__content__tabs__tab__shop__content__image ${imgOrderClass(k)}`} >
                                        <Image src={ constants.urls.UPLOAD + it.logo} alt="single shop image"/>
                                    </div>
                                    <div className={`shop__content__tabs__tab__shop__content__info ${infoOrderClass(k)}`}>
                                        <h3 className="shop__content__tabs__tab__shop__content__info__title">{it.title}</h3>
                                        <p className="shop__content__tabs__tab__shop__content__info__text">{it.description}</p>
                                        <p className="shop__content__tabs__tab__shop__content__info__price">{it.price} {it.currency.toUpperCase()}</p>
                                        <a href={it.link}
                                                 className="shop__content__tabs__tab__shop__content__info__details">Click
                                            here for more details</a>
                                        <a href={`mailto:info@circletechnicaldesign.com?subject=Shop:%20${it.title}&body=Hi%0A%0AI%20would%20like%20to%20buy%20below%20mentioned%20product:%0A%0AImage:%20${constants.urls.UPLOAD + it.logo}%0A%0ATitle:%20${it.title}%0A%0ADescription:%20${it.description}%0A%0APrice:%20${it.price}%20${it.currency.toUpperCase()}%0A%0A%0A%0ARegards,`}
                                           className="shop__content__tabs__tab__shop__content__info__order">order
                                            by email
                                        </a>
                                    </div>
                                </div>
                            })}
                            {(request_data?.last_page > 1 && request_data?.current_page < request_data?.last_page) ? (
                            <div
                                className={`blog-load ${loadMore ? "hide" : ""}`}
                                onClick={() => loadMoreAllItems(request_data?.current_page + 1)}
                            >
                                <span className="text">Load more</span>
                                <div
                                className={`spinner-border ${
                                    hideLoadMoreLoader ? "hide" : ""
                                }`}
                                role="status"
                                >
                                <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            ) : (
                            ""
                            )}
                        </div>
                    </Tab>
                    {cat_data && cat_data.map(function(item, i) {
                        return <Tab eventKey={item.id} title={item.name} className="shop__content__tabs__tab">
                            {   hideLoadMoreLoader && <div
                                    className={`blog-load`}
                                >
                                    <span>Loading...</span>
                                    <div
                                    className={`spinner-border`}
                                    role="status"
                                    >
                                    </div>
                                </div>
                            }
                            <div className="shop__content__tabs__tab__shop">
                                {request_category_data && request_category_data.data.map(function(it, j) {
                                return <div className="shop__content__tabs__tab__shop__content">
                                        <div className={`shop__content__tabs__tab__shop__content__image ${imgOrderClass(j)}`} >
                                            <Image src={ constants.urls.UPLOAD + it.logo} alt="single shop image"/>
                                        </div>
                                        <div className={`shop__content__tabs__tab__shop__content__info ${infoOrderClass(j)}`}>
                                            <h3 className="shop__content__tabs__tab__shop__content__info__title">{it.title}</h3>
                                            <p className="shop__content__tabs__tab__shop__content__info__text">{it.description}</p>
                                            <p className="shop__content__tabs__tab__shop__content__info__price">{it.price} {it.currency.toUpperCase()}</p>
                                            <a href={it.link}
                                                     className="shop__content__tabs__tab__shop__content__info__details">Click
                                                here for more details</a>
                                            <a href={`mailto:info@circletechnicaldesign.com?subject=Shop:%20${it.title}&body=Hi%0A%0Awould%20like%20to%20buy%20below%20mentioned%20product:%0A%0AImage:%20${constants.urls.UPLOAD + it.logo}%0A%0ATitle:%20${it.title}%0A%0ADescription:%20${it.description}%0A%0APrice:%20${it.price}%20${it.currency.toUpperCase()}%0A%0A%0A%0ARegards,`}
                                               className="shop__content__tabs__tab__shop__content__info__order">order
                                                by email
                                            </a>
                                        </div>
                                    </div>
                                })}
                                {(request_category_data?.last_page > 1 && request_category_data?.current_page < request_category_data?.last_page) ? (
                                <div
                                    className={`blog-load ${loadMore ? "hide" : ""}`}
                                    onClick={() => loadMoreItemsByCategory(item.id ,request_category_data?.current_page + 1)}
                                >
                                    <span className="text">Load more</span>
                                    <div
                                    className={`spinner-border ${
                                        hideLoadMoreLoader ? "hide" : ""
                                    }`}
                                    role="status"
                                    >
                                    <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                ) : (
                                ""
                                )}
                            </div>
                        </Tab>
                    })}

                </Tabs>
            </div>
        </div>
    )
}
// <script>
//     $( "nav a:first-child" ).addClass( "active" );
// </script>
export default Shop ;
