import { GoogleReCaptchaProvider,GoogleReCaptcha } from 'react-google-recaptcha-v3';
import constants from "../../helpers/constants";
import pdfFile from '../../assets/img/Home/icons/pdf-file.svg';
import cvImg from "../../assets/img/cv.png";


const SendResumeForm = ({position}) => {
  const work_email = `${constants.urls.API}/AddWorkWithUs`;
  var allFiles = [];
  const onChangeHandler=event=>{

    //get files name
    const photos = document.querySelector('input[type="file"][multiple]');
    var  ul = document.querySelector(".team__work__container__form__main__left__row__files__list");
    var  acceptFiles = document.querySelector("#accept_files");
    var validFiles = true;
    for(let i = 0; i<photos.files.length; i++) {
        var ext = photos.files[i].name.split('.').pop();
        if(ext != "pdf"){
            validFiles = false;
        }
    }

    // if(ext=="pdf" || ext=="docx" || ext=="doc"){
    if(validFiles){
        acceptFiles.innerHTML = "";
    } else{
        acceptFiles.innerHTML = "Choose PDF format!";
        return false;
    }
    for (let i = 0; i < photos.files.length; i++) {
        allFiles.push(photos.files[i]);
    }
    for (let i = 0; i < photos.files.length; i++) {
        //console.log(photos.files[i].name);
        var li = document.createElement("li");
        var p = document.createElement("p");
        var image = document.createElement("IMG");
        var span = document.createElement("SPAN");
        li.setAttribute("class", "team__work__container__form__main__left__row__files__list__item");
        p.setAttribute("class", "team__work__container__form__main__left__row__files__list__item__info");
        image.setAttribute("class", "team__work__container__form__main__left__row__files__list__item__info__image");
        image.setAttribute("src", pdfFile);
        ul.appendChild(li);
        li.appendChild(p);
        p.append(image);
        p.append(span);
        if(photos.files[i].name.length < 13){
            span.innerHTML = photos.files[i].name.substring(0,13);
        }
        else{span.innerHTML = photos.files[i].name.substring(0,13)+'...';}
    }

  }

  const workSubmit= ( e ) => {
    e.preventDefault();

    const formData = new FormData();
    const photos = document.querySelector('input[type="file"][multiple]');
    const email = document.querySelector('#work_email').value;
    const message = document.querySelector('#work_message').value;
    //validation
    var emailAlertText = document.querySelector('#email_text');
    var messageAlertText = document.querySelector('#message_text');
    var sentForm = document.querySelector('#sent_form');
    var  acceptFiles = document.querySelector("#accept_files");
    console.log(photos.files.length,'------', allFiles);
    if(photos.files.length === 0){
        acceptFiles.innerHTML = "Please attach your CV";
    }
    if(email != ''){
        if( /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)){
            emailAlertText.innerHTML = "";
            //return true;
        } else {
            emailAlertText.innerHTML = "You have entered an invalid email address!";
            //return false;
        }

    }else{
        emailAlertText.innerHTML = "This field is required";
      //  return false;
    }

    if(message != ''){
        messageAlertText.innerHTML = "";
    } else {
        messageAlertText.innerHTML = "This field is required";
        return false;
    }

    formData.append('sent', 'Work With Us Position: '+ position);
    formData.append('position', position);
    formData.append('email', email);
    formData.append('message', message);
     for (let i = 0; i < allFiles.length; i++) {
        formData.append('photos[]', allFiles[i]);
        //console.log('photos[]',photos);
     }
    console.log('Form Data --- ',allFiles);
    fetch(work_email, {
        method: 'POST',
        body: formData,
    })
        .then(response => response.json())
        .then(result => {
            console.log('Success:', result);
            sentForm.innerHTML = "Your message has been successfully sent.";
            setTimeout(function(){
                sentForm.innerHTML = "";
            }, 3000);
            document.querySelector('input[type="file"][multiple]').value = '';
            document.querySelector('#work_email').value = '';
            document.querySelector('#work_message').value = '';
            document.querySelector("#ul_file_name ").innerHTML = "";
            acceptFiles.innerHTML = "";
            emailAlertText.innerHTML = "";
            messageAlertText.innerHTML = "";

        })
        .catch(error => {
            console.error('Error:', error);
        });
  }
  return (
    <div className="team__work">
      <div className="container">
          <div className="row">
              <div className="col-12">
                  <div className="team__work__container">
                      <h2 className="team__work__container__title">DO YOU WANT TO WORK WITH US?</h2>
                      <div className="team__work__container__form">
                          <GoogleReCaptchaProvider reCaptchaKey="6LetMZccAAAAAKGBAS00AB_ByzAdiGuyDvAn46em">
                              <form method="POST" action={work_email} encType="multipart/form-data">
                                  <h4 id="email_text" className="error_message"></h4>
                                  <div  className="team__work__container__form__main">
                                      <div className="team__work__container__form__main__left">
                                          <input id="work_email"  type="email" required name="email"  placeholder="email@email.com*" className="team__work__container__form__main__left__email"/>
                                          <input id="work_quote" type="hidden" name="work"  value="Work With Us"/>
                                          <div  className="team__work__container__form__main__left__row">
                                              <div className="team__work__container__form__main__left__row__quote">
                                                  <h4 id="message_text"  className="error_message"></h4>
                                                  <textarea name="message" required id="work_message" cols="30" rows="8" placeholder="Write about your work here *"  className="team__work__container__form__main__left__row__quote__textarea"></textarea>
                                              </div>
                                              <div className="team__work__container__form__main__left__row__files">
                                                  <ul id="ul_file_name" className="team__work__container__form__main__left__row__files__list">
                                                  </ul>
                                              </div>
                                              <h4 id="accept_files" className="error_message"></h4>
                                              <h4 id="sent_form" className="success_message"></h4>
                                          </div>
                                      </div>
                                      <div className="team__work__container__form__main__right">
                                          <div className="team__work__container__form__main__right__file">
                                              <label className="team__work__container__form__main__right__file__label" htmlFor="attach">
                                                  <img src={cvImg} style={{width: '100%'}} />
                                                  <span>*</span>
                                              </label>
                                              <input onChange={onChangeHandler} className="team__work__container__form__main__right__file__input" type="file" id="attach" name="images[]" required multiple accept="application/pdf"/>{/*application/vnd.ms-excel*/}
                                          </div>
                                          
                                          <button onClick={workSubmit} className="team__work__container__form__main__right__submit">send </button>
                                      </div>
                                  </div>
                              </form>
                          </GoogleReCaptchaProvider>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  )
}

export default SendResumeForm