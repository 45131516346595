import { React, useEffect, useState } from "react";
import { Image, Tabs, Tab } from "react-bootstrap";
import { NavLink } from "react-router-dom";
// import bannerBg from "../../assets/img/Works/bannerBg.png";
// import work1 from "../../assets/img/Works/work/1.png";
// import work2 from "../../assets/img/Works/work/2.png";
import constants from "../../helpers/constants";

function Works(props) {
  const our_works_main_url = `${constants.urls.API}/getOurWorksImage`;
  const our_works_url = `${constants.urls.API}/getAllWork`;
  // const our_works_by_category_url = `${constants.urls.API}/getOurWorksByCategory`;
  const [data, setData] = useState(null);

  const [request_data, setReqData] = useState(null);
  const [branding, setBranding] = useState(null);
  const [technical, setTechnical] = useState(null);
  const [interior, setInterior] = useState(null);

  // const [request_by_cat_data, setReqByCatData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadMore, setLoadMore] = useState();
  const [hideLoadMoreLoader, setHideLoadMoreLoader] = useState();

  const [allPage, setAllPage] = useState(1);
  const [allBrandingPage, setBrandingPage] = useState(1);
  const [allTechnicalPage, setTechnicalPage] = useState(1);
  const [allInteriorPage, setInteriorPage] = useState(1);

  const [allResultCount, setAllResultCount] = useState();
  const [brandingResultCount, setBrandingResultCount] = useState();
  const [technicalResultCount, setTechnicalResultCount] = useState();
  const [interiorResultCount, setInteriorResultCount] = useState();

  function getAllWorks() {
    fetch(`${our_works_url}?limit=9&page=${allPage}`, { method: "GET" })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.work) {
          const works = data.work.data;
          setAllResultCount(data.allItems);
          setHideLoadMoreLoader(true);

          if (request_data && request_data.length) {
            let oldData = request_data.concat(works);
            setReqData(oldData);
          } else {
            setReqData(works);
          }
        }
      });
  }

  function getBrandingWorks() {
    fetch(`${our_works_url}?category_id=1&limit=9&page=${allBrandingPage}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.work) {
          const works = data.work.data;
          setBrandingResultCount(data.allItems);
          setHideLoadMoreLoader(true);

          if (branding && branding.length) {
            let oldData = branding.concat(works);
            setBranding(oldData);
          } else {
            setBranding(works);
          }
        }
      });
  }

  function getTechnicalWorks() {
    fetch(`${our_works_url}?category_id=2&limit=9&page=${allTechnicalPage}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.work) {
          const works = data.work.data;
          setTechnicalResultCount(data.allItems);
          setHideLoadMoreLoader(true);

          if (branding && technical.length) {
            let oldData = technical.concat(works);
            setTechnical(oldData);
          } else {
            setTechnical(works);
          }
        }
      });
  }

  function getInteriorWorks() {
    fetch(`${our_works_url}?category_id=3&limit=9&page=${allInteriorPage}`, {
      method: "GET",
    })
      .then((response) => response.json())

      .then((data) => {
        if (data && data.work) {
          const works = data.work.data;
          setInteriorResultCount(data.allItems);
          setHideLoadMoreLoader(true);

          if (interior && interior.length) {
            let oldData = interior.concat(works);
            setInterior(oldData);
          } else {
            setInterior(works);
          }
        }
      });
  }

  function loadMoreAllItems() {
    setAllPage(allPage + 1);
    setHideLoadMoreLoader(false);
  }
  function loadMoreBrandingItems() {
    setBrandingPage(allBrandingPage + 1);
    setHideLoadMoreLoader(false);
  }
  function loadMoreTechnicalItems() {
    setTechnicalPage(allTechnicalPage + 1);
    setHideLoadMoreLoader(false);
  }
  function loadMoreInteriorItems() {
    setInteriorPage(allInteriorPage + 1);
    setHideLoadMoreLoader(false);
  }

  // getAllWork
  useEffect(() => {
    //   // getAllWorks();
    //   // getBraandingWorks();
    //   // getTechnicalWorks();
    //   // getInteriorWorks();
    setIsLoaded(false);

    Promise.all([
      fetch(our_works_main_url, {
        method: `GET`,
      }),
    ])
      .then((res) => {
        return Promise.all(
          res.map((response) => {
            return response.json();
          })
        );
      })
      .then((result) => {
        if (result && result[0]) {
          setData(result[0].our_works_image[0]);
        }
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.match.params.id]);

  useEffect(() => {
    getAllWorks();
  }, [props.match.params.id, allPage]);

  useEffect(() => {
    getBrandingWorks();
  }, [props.match.params.id, allBrandingPage]);

  useEffect(() => {
    getTechnicalWorks();
  }, [props.match.params.id, allTechnicalPage]);

  useEffect(() => {
    getInteriorWorks();
  }, [props.match.params.id, allInteriorPage]);

  if (!isLoaded) {
    return (
      <div className="loader">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <div className="works">
      <div
        className="works__banner"
        style={{
          backgroundImage: `url(${data?.image})`,
        }}
      >
        {data?.title && <h1 className="works__banner__title">{data.title}</h1>}
      </div>
      <div className="works__content">
        <Tabs
          defaultActiveKey="all"
          id="uncontrolled-tab-example"
          className="works__content__tabs"
        >
          <Tab eventKey="all" title="All" className="works__content__tabs__tab">
            <div className="works__content__tabs__tab__container">
              {request_data &&
                request_data.map(function (item, i) {
                  return (
                    <div
                      key={"product-" + item.id}
                      className="works__content__tabs__tab__container__item"
                    >
                      {/* <div>{item.workScheme}</div> */}
                      <div className="works__content__tabs__tab__container__item__image">
                        <NavLink to={`/works/${item.id}`}>
                          <Image
                            src={
                              item.image !== ""
                                ? constants.urls.UPLOAD+''+item.image
                                : "https://i.stack.imgur.com/y9DpT.jpg"
                            }
                            alt={item.title}
                          />
                        </NavLink>
                      </div>
                      <div className="works__content__tabs__tab__container__item__info">
                        <NavLink
                          to={`/works/${item.id}`}
                          className="works__content__tabs__tab__container__item__info__link"
                        >
                          <div className="works__content__tabs__tab__container__item__info__link__content">
                            <h3 className="works__content__tabs__tab__container__item__info__link__content__title">
                              {item.title}
                            </h3>
                            <p className="works__content__tabs__tab__container__item__info__link__content__subtitle">
                              {item.description}
                            </p>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  );
                })}
            </div>

            {allResultCount !== (request_data && request_data.length) ? (
              <div
                className={`blog-load ${loadMore ? "hide" : ""}`}
                onClick={() => loadMoreAllItems()}
              >
                <span className="text">Load more</span>
                <div
                  className={`spinner-border ${
                    hideLoadMoreLoader ? "hide" : ""
                  }`}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              ""
            )}
          </Tab>

          <Tab
            eventKey="branding"
            title="Branding"
            className="works__content__tabs__tab"
          >
            <div className="works__content__tabs__tab__container">
              {branding &&
                branding.map(function (item, i) {
                  return (
                    <div
                      key={"product-" + item.id}
                      className="works__content__tabs__tab__container__item"
                    >
                      {/* <div>{item.workScheme}</div> */}
                      <div className="works__content__tabs__tab__container__item__image">
                        <NavLink to={`/works/${item.id}`}>
                          <Image
                            src={
                              item.image !== ""
                                ? constants.urls.UPLOAD+''+item.image
                                : "https://i.stack.imgur.com/y9DpT.jpg"
                            }
                            alt={item.title}
                          />
                        </NavLink>
                      </div>
                      <div className="works__content__tabs__tab__container__item__info">
                        <NavLink
                          to={`/works/${item.id}`}
                          className="works__content__tabs__tab__container__item__info__link"
                        >
                          <div className="works__content__tabs__tab__container__item__info__link__content">
                            <h3 className="works__content__tabs__tab__container__item__info__link__content__title">
                              {item.title}
                            </h3>
                            <p className="works__content__tabs__tab__container__item__info__link__content__subtitle">
                              {item.description}
                            </p>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  );
                })}
            </div>
            {brandingResultCount !== (branding && branding.length) ? (
              <div
                className={`blog-load ${loadMore ? "hide" : ""}`}
                onClick={() => loadMoreBrandingItems()}
              >
                <span className="text">Load more</span>
                <div
                  className={`spinner-border ${
                    hideLoadMoreLoader ? "hide" : ""
                  }`}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              ""
            )}
          </Tab>

          <Tab
            eventKey="technical"
            title="Technical"
            className="works__content__tabs__tab"
          >
            <div className="works__content__tabs__tab__container">
              {technical &&
                technical.map(function (item, i) {
                  return (
                    <div
                      key={"product-" + item.id}
                      className="works__content__tabs__tab__container__item"
                    >
                      {/* <div>{item.workScheme}</div> */}
                      <div className="works__content__tabs__tab__container__item__image">
                        <NavLink to={`/works/${item.id}`}>
                          <Image
                            src={
                              item.image !== ""
                                ? constants.urls.UPLOAD+''+item.image
                                : "https://i.stack.imgur.com/y9DpT.jpg"
                            }
                            alt={item.title}
                          />
                        </NavLink>
                      </div>
                      <div className="works__content__tabs__tab__container__item__info">
                        <NavLink
                          to={`/works/${item.id}`}
                          className="works__content__tabs__tab__container__item__info__link"
                        >
                          <div className="works__content__tabs__tab__container__item__info__link__content">
                            <h3 className="works__content__tabs__tab__container__item__info__link__content__title">
                              {item.title}
                            </h3>
                            <p className="works__content__tabs__tab__container__item__info__link__content__subtitle">
                              {item.description}
                            </p>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  );
                })}
            </div>

            {technicalResultCount !== (technical && technical.length) ? (
              <div
                className={`blog-load ${technical ? "hide" : ""}`}
                onClick={() => loadMoreTechnicalItems()}
              >
                <span className="text">Load more</span>
                <div
                  className={`spinner-border ${
                    hideLoadMoreLoader ? "hide" : ""
                  }`}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              ""
            )}
          </Tab>

          <Tab
            eventKey="interior"
            title="Interior"
            className="works__content__tabs__tab"
          >
            <div className="works__content__tabs__tab__container">
              {interior &&
                interior.map(function (item, i) {
                  return (
                    <div
                      key={"product-" + item.id}
                      className="works__content__tabs__tab__container__item"
                    >
                      {/* <div>{item.workScheme}</div> */}
                      <div className="works__content__tabs__tab__container__item__image">
                        <NavLink to={`/works/${item.id}`}>
                          <Image
                            src={
                              item.image !== ""
                                ? constants.urls.UPLOAD+''+item.image
                                : "https://i.stack.imgur.com/y9DpT.jpg"
                            }
                            alt={item.title}
                          />
                        </NavLink>
                      </div>
                      <div className="works__content__tabs__tab__container__item__info">
                        <NavLink
                          to={`/works/${item.id}`}
                          className="works__content__tabs__tab__container__item__info__link"
                        >
                          <div className="works__content__tabs__tab__container__item__info__link__content">
                            <h3 className="works__content__tabs__tab__container__item__info__link__content__title">
                              {item.title}
                            </h3>
                            <p className="works__content__tabs__tab__container__item__info__link__content__subtitle">
                              {item.description}
                            </p>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  );
                })}
            </div>{" "}
            {interiorResultCount !== (interior && interior.length) ? (
              <div
                className={`blog-load ${interior ? "hide" : ""}`}
                onClick={() => loadMoreInteriorItems()}
              >
                <span className="text">Load more</span>
                <div
                  className={`spinner-border ${
                    hideLoadMoreLoader ? "hide" : ""
                  }`}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              ""
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Works;
