import Blog from "./components/pages/Blog";
import BlogSingle from "./components/pages/BlogSingle";
import Career from "./components/pages/Career";
import Home from "./components/pages/Home";
import Services from "./components/pages/Services";
import Shop from "./components/pages/Shop";
import Team from "./components/pages/Team";
import Vacancy from "./components/pages/Vacancy";
import Works from "./components/pages/Works";
import WorkSingle from "./components/pages/WorkSingle";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/team",
    exact: false,
    component: Team,
  },
  {
    path: "/services",
    exact: false,
    component: Services,
  },
  {
    path: "/works/:id",
    exact: false,
    component: WorkSingle,
  },
  {
    path: "/works",
    exact: false,
    component: Works,
  },
  {
    path: "/blog/:id",
    exact: false,
    component: BlogSingle,
  },
  {
    path: "/blog",
    exact: false,
    component: Blog,
  },
  {
    path: "/shop",
    exact: false,
    component: Shop,
  },
  {
    path: "/career",
    exact: false,
    component: Career,
  },
  {
    path: "/vacancy/:id",
    exact: false,
    component: Vacancy,
  },
];
