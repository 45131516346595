import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import bannerBg from "../../assets/img/Blog/bannerBg.png";
import constants from "../../helpers/constants";
import SendResumeForm from "../elements/SendResumeForm";

function Vacancy(props) {
  const [pageSingle, setPageSingle] = useState([]);
  const id = props.match.params.id;
  const single_url = `${constants.urls.API}/getVacancy/${id}`;
  const [isLoaded, setIsLoaded] = useState(false);


  function singlePage() {
    setIsLoaded(false)
    Promise.all([
      fetch(single_url, {
        method: `GET`,
      }),
    ])
      .then((res) => {
        return Promise.all(
          res.map((response) => {
            return response.json();
          })
        );
      })
      .then((result) => {
        if (result && result[0]) {
          setPageSingle(result[0].vacancy);
        }
        setIsLoaded(true)
      })
      .catch((err) => {
        console.log(err);

        /* scroll animation end */
      });
  }
  useEffect(() => {
    singlePage();
  }, [props.match.params.id]);

  if (!isLoaded) {
    return (
      <div className="loader">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="page">
      {pageSingle &&
        <div
          className="banner"
          style={{ backgroundImage: `url(${constants.urls.UPLOAD+pageSingle.logo})` }}
        >
          {pageSingle?.title && <h1>{pageSingle.title}</h1>}
        </div>
      }
      <div className="container container--middle--lg">
        {pageSingle && 
          <div className="page__content">
            <div
              dangerouslySetInnerHTML={{__html: pageSingle.description}}
            />
          </div>
        }
      </div>
      <SendResumeForm position={pageSingle?.title} />
    </div>
  );
}

export default Vacancy;
