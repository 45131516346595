const allConstants = {};

if (process.env.NODE_ENV === "development") {
  allConstants.urls = {
    UPLOAD: "http://localhost/uploads/",
    API: "http://localhost/api",

    // UPLOAD: "https://admin.circletechnicaldesign.com/uploads/",
    // API: "https://admin.circletechnicaldesign.com/api",
  };
} else {
  allConstants.urls = {
    UPLOAD: "https://admin.circletechnicaldesign.com/uploads/",
    API: "https://admin.circletechnicaldesign.com/api",
  };
}

export default allConstants;
